import pRetry from "p-retry";
import type { User } from "firebase/auth";
import { getIdToken } from "firebase/auth";

import type { RimoHttpRequestConfig } from ".";
import Sentry from "../utils/sentry";

export const getAccessToken = async (config: RimoHttpRequestConfig, user: User) => {
  const value = config.data?.rimoForceRefreshToken;
  const forceRefresh = typeof value === "boolean" && value === true;
  const token = await pRetry((count) => getIdToken(user, count === 1 ? forceRefresh : false), {
    retries: 3,
    onFailedAttempt(error) {
      Sentry.captureException(error, {
        contexts: {
          apiRetry: {
            kind: "getIdToken",
            url: config.url,
            attemptNumber: error.attemptNumber.toString(),
            retriesLeft: error.retriesLeft.toString(),
          },
        },
      });
    },
  });

  return token;
};
