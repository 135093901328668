import React from "react";
import styled, { css } from "styled-components";
import type { TransientProps } from "./transientProps";

type Props = {
  inputId: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  checked: boolean;
  disabled?: boolean;
};

export const Switch: React.FC<Props> = (props) => {
  return (
    <div>
      <CheckboxInput
        type="checkbox"
        id={props.inputId}
        onChange={props.onChange}
        checked={props.checked}
        disabled={props.disabled}
      />
      <SwitchLabel $checked={props.checked} htmlFor={props.inputId} />
    </div>
  );
};

const SwitchLabel = styled.label<TransientProps<{ checked: boolean }>>`
  cursor: pointer;
  width: 44px;
  height: 28px;
  box-sizing: border-box;
  border: 2px solid ${(p) => p.theme.vars.palette.text.secondary};
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  transform: all 200ms;
  &::before {
    display: block;
    content: "";
    width: 16px;
    height: 16px;
    background: ${(p) => p.theme.vars.palette.text.secondary};
    border-radius: 100px;
  }
  ${(p) =>
    p.$checked &&
    css`
      background: ${(p) => p.theme.vars.palette.primary.lightSurface};
      border-color: ${(p) => p.theme.vars.palette.primary.main};
      &::before {
        background: ${(p) => p.theme.vars.palette.primary.main};
      }
    `}
`;
const CheckboxInput = styled.input`
  display: none;
  &:checked {
    & ~ ${SwitchLabel} {
      flex-direction: row-reverse;
    }
  }
  &:disabled {
    & ~ ${SwitchLabel} {
      opacity: 0.3;
    }
  }
`;
