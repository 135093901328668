// import type { AuthProvider } from "@rimo/frontend/types/auth";

import { getAuth } from "firebase/auth";
import { isRimoLLC } from "@rimo/frontend/utils/system";
import type { Organization, OrganizationInfoDisabledSupportsItem } from "@rimo/frontend/api-client/models";

export type Support = OrganizationInfoDisabledSupportsItem;

export type Prepaid = {
  amount_remaining: number;
  duration_sec_remaining: number;
  end_at: string;
  start_at: string;
};

export const TeamRole = {
  SuperAdmin: "super_admin",
  Admin: "admin",
  Member: "member",
  Invitation: "invitation",
} as const;
export type TeamRole = (typeof TeamRole)[keyof typeof TeamRole];

export const TeamLabel: { [key in TeamRole]: string } = {
  super_admin: "特権管理者",
  admin: "管理者",
  member: "メンバー",
  invitation: "招待中",
};
export type PayPlanCode = "prepaid_plan" | "subscription_plan" | "pay_plan";

export const getTeamRoleString = (role: string) => {
  switch (role) {
    case TeamRole.SuperAdmin:
      return TeamLabel["super_admin"];
    case TeamRole.Admin:
      return TeamLabel["admin"];
    case TeamRole.Member:
      return TeamLabel["member"];
    case TeamRole.Invitation:
      return TeamLabel["invitation"];
    default:
      return "不明";
  }
};

export function isTeamRole(role: string): role is TeamRole {
  return (Object.values(TeamRole) as string[]).includes(role);
}

export const isAdminTeamRole = (role: string | null | undefined) => {
  return role === TeamRole.SuperAdmin || role === TeamRole.Admin;
};

export const isPersonalOrganization = (org: Organization, uid?: string | undefined): boolean => {
  const user = getAuth().currentUser;
  return org.id === `user_${uid ?? user?.uid ?? ""}`;
};

export const isPersonalOrganizationId = (org: Organization): boolean => {
  return org.id.startsWith(`user_`);
};

// 将来的には organization_info に持たせる
const customDownloadEnabledOrgs = [
  "wIb1ctbsbsFU29hQWeHn", // 大成建設株式会社建築本部プロジェクト・マネジメント部
  "smdIkrHVSCJiCrAEbSKB", // Rimo-yyamada-test
];
export const canUseCustomDownload = (orgId: string): boolean => {
  return customDownloadEnabledOrgs.includes(orgId) || isRimoLLC(orgId);
};
