"use client";

import type { SupportedColorScheme } from "@mui/material/styles";
import { useColorScheme } from "@mui/material/styles";
import React, { createContext, useCallback, useContext, useMemo } from "react";
import type { DefaultTheme } from "styled-components";
import { ThemeProvider } from "styled-components";
import { useIsomorphicLayoutEffect } from "../lib/useIsomorphicLayoutEffect";
import { theme } from "./themePalette";

export type Theme = SupportedColorScheme;
// Export for testing
export const ThemeModeContext = createContext<Theme>("light");

export const LOCAL_STORAGE_KEY = "color-theme";

type SwitchThemeMode = () => void;
// Export for testing
export const SwitchThemeModeContext = createContext<SwitchThemeMode | undefined>(undefined);

export const ThemeModeProvider: React.FC<React.PropsWithChildren<{ overrideMode?: Theme | null }>> = ({
  overrideMode,
  children,
}) => {
  const { mode = "system", setMode, systemMode } = useColorScheme();

  const lightOrDark = mode === "system" ? (systemMode ?? "light") : mode;

  const switchMode = useCallback(() => {
    const nextTheme = lightOrDark === "light" ? "dark" : "light";
    setMode(nextTheme);
  }, [lightOrDark, setMode]);

  const themeValue = useMemo(() => {
    return {
      ...theme,
      mode: lightOrDark,
    };
  }, [lightOrDark]);

  return (
    <ThemeProvider theme={themeValue}>
      <ThemeModeContext.Provider value={lightOrDark}>
        <SwitchThemeModeContext.Provider value={switchMode}>
          <ThemeOverrideProvider overrideMode={overrideMode ?? null}>{children}</ThemeOverrideProvider>
        </SwitchThemeModeContext.Provider>
      </ThemeModeContext.Provider>
    </ThemeProvider>
  );
};

export const ThemeOverrideProvider: React.FC<React.PropsWithChildren<{ overrideMode: Theme | null }>> = ({
  overrideMode,
  children,
}) => {
  const themeValue: DefaultTheme = useMemo(() => {
    if (overrideMode === null) return theme;
    return {
      ...theme,
      mode: overrideMode,
    };
  }, [overrideMode]);

  const contextMode = useContext(ThemeModeContext);
  if (contextMode === undefined) throw new Error("You might use ThemeOverrideProvider outside of ThemeModeProvider");

  useIsomorphicLayoutEffect(() => {
    if (overrideMode) {
      document.body.setAttribute("data-mui-color-scheme", overrideMode);
    }
    return () => {
      document.body.removeAttribute("data-mui-color-scheme");
    };
  }, [overrideMode]);

  return (
    <ThemeProvider theme={themeValue}>
      <ThemeModeContext.Provider value={overrideMode ?? contextMode}>{children}</ThemeModeContext.Provider>
    </ThemeProvider>
  );
};

export const useThemeMode: () => Theme = () => {
  const ctx = useContext(ThemeModeContext);
  if (ctx === undefined) {
    throw new Error("You might use useThemeMode outside of ThemeModeProvider");
  }

  return ctx;
};

export const useToggleColorMode = () => {
  const ctx = useContext(SwitchThemeModeContext);
  if (ctx === undefined) {
    throw new Error("You might use useThemeSwitch outside of SwitchThemeModeProvider");
  }
  return ctx;
};
