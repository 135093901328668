import type { Organization, TeamMember } from "@rimo/frontend/api-client/models";
import type { Team } from "@rimo/frontend/api-client/models";
import { TeamRole } from "./organization";

export * from "./organization";

export type OrgInviteValidationResponse = {
  invitation_count: number;
  is_forbidden: boolean;
  is_warning: boolean;
  member_count: number;
  reserved_count: number;
};

/**
 * メンバー名を取得する
 *
 * - (is_activated: true) はユーザー名を取得出来る
 * - (is_activated: false) はメールアドレスが取得出来る(data.profileはFalsy)
 */
export const getTeamMemberName = (data: TeamMember): { firstName: string; lastName: string } => {
  const separator = " ";
  const profile = data.profile;
  if (!profile || (profile.lastName === "" && profile.firstName === "")) {
    if (data.name) {
      if (data.name.includes(" ")) {
        const [lastName, firstName] = data.name.split(separator);
        return { firstName, lastName };
      }
      return { lastName: "", firstName: data.name };
    }
    return { lastName: "", firstName: "" };
  }
  if (profile.lastName === "" && profile.firstName.includes(separator)) {
    const [lastName, firstName] = profile.firstName.split(separator);
    return { firstName, lastName };
  }
  return { lastName: profile.lastName, firstName: profile.firstName };
};

export const sortTeamMembersByAscii = (members: TeamMember[]) => {
  return [...members].sort((a, b) => {
    return a.name < b.name ? -1 : 1;
  });
};

export const getActiveTeamMembers = (members: TeamMember[]) => {
  const arr = members.filter((v) => v.isActivated);
  return sortTeamMembersByAscii(arr);
};

export const getInvitedTeamMembers = (members: TeamMember[]) => {
  const arr = members.filter((v) => !v.isActivated);
  return sortTeamMembersByAscii(arr);
};

export const getTeamMembersByRole = (members: TeamMember[], role: TeamRole | string) => {
  if (role === TeamRole.SuperAdmin) {
    return members.filter((v) => v.role === TeamRole.SuperAdmin);
  }
  if (role === TeamRole.Admin) {
    return members.filter((v) => v.role === TeamRole.Admin);
  }
  if (role === TeamRole.Member) {
    return members.filter((v) => v.role === TeamRole.Member);
  }
  if (role === TeamRole.Invitation) {
    return members.filter((v) => v.role === TeamRole.Invitation);
  }
  return members;
};

export const getChannelIdByTeam = (team: Team | Organization | string): string =>
  `team${typeof team === "string" ? team : team.id}`;

export const isOrgTeam = (team: Team): boolean => team.category === "organization";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isTeam = (target: any): target is Team => {
  return target && typeof target.category === "string" && target.category === "team";
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isOrganization = (target: any): target is Organization => {
  if (!target && !(target as Team).category) return false;
  return target?.category === "organization";
};
