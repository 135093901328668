/**
 * Generated by orval v7.4.0 🍺
 * Do not edit manually.
 * RimoVoice Service API
 * OpenAPI spec version: 0.0.0
 */
import useSwr from "swr";
import type { Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";
import type {
  Organization,
  OrganizationInfo,
  OrganizationInfoUpdate,
  OrganizationSecurity,
  OrganizationSetting,
  OrganizationSettingUpdate,
  OrganizationsListParams,
  OrganizationsReadParams,
} from ".././models";
import { customInstance } from "../../api/customInstance";
import type { ErrorType, BodyType } from "../../api/customInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const organizationsList = (
  params?: OrganizationsListParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Organization[]>({ url: `/organizations`, method: "GET", params }, options);
};

export const getOrganizationsListKey = (params?: OrganizationsListParams) =>
  [`/organizations`, ...(params ? [params] : [])] as const;

export type OrganizationsListQueryResult = NonNullable<Awaited<ReturnType<typeof organizationsList>>>;
export type OrganizationsListQueryError = ErrorType<unknown>;

export const useOrganizationsList = <TError = ErrorType<unknown>>(
  params?: OrganizationsListParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof organizationsList>>, TError> & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getOrganizationsListKey(params) : null));
  const swrFn = () => organizationsList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
export const organizationsRead = (
  id: string,
  params?: OrganizationsReadParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Organization>({ url: `/organizations/${id}`, method: "GET", params }, options);
};

export const getOrganizationsReadKey = (id: string, params?: OrganizationsReadParams) =>
  [`/organizations/${id}`, ...(params ? [params] : [])] as const;

export type OrganizationsReadQueryResult = NonNullable<Awaited<ReturnType<typeof organizationsRead>>>;
export type OrganizationsReadQueryError = ErrorType<unknown>;

export const useOrganizationsRead = <TError = ErrorType<unknown>>(
  id: string,
  params?: OrganizationsReadParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof organizationsRead>>, TError> & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getOrganizationsReadKey(id, params) : null));
  const swrFn = () => organizationsRead(id, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
export const organizationsInfoRead = (organizationId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<OrganizationInfo>({ url: `/organizations/${organizationId}/info`, method: "GET" }, options);
};

export const getOrganizationsInfoReadKey = (organizationId: string) =>
  [`/organizations/${organizationId}/info`] as const;

export type OrganizationsInfoReadQueryResult = NonNullable<Awaited<ReturnType<typeof organizationsInfoRead>>>;
export type OrganizationsInfoReadQueryError = ErrorType<unknown>;

export const useOrganizationsInfoRead = <TError = ErrorType<unknown>>(
  organizationId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof organizationsInfoRead>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!organizationId;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getOrganizationsInfoReadKey(organizationId) : null));
  const swrFn = () => organizationsInfoRead(organizationId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
export const organizationsInfoUpdate = (
  organizationId: string,
  organizationInfoUpdate: BodyType<OrganizationInfoUpdate>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<OrganizationInfo>(
    {
      url: `/organizations/${organizationId}/info`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: organizationInfoUpdate,
    },
    options
  );
};

export const getOrganizationsInfoUpdateMutationFetcher = (
  organizationId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return (_: Key, { arg }: { arg: OrganizationInfoUpdate }): Promise<OrganizationInfo> => {
    return organizationsInfoUpdate(organizationId, arg, options);
  };
};
export const getOrganizationsInfoUpdateMutationKey = (organizationId: string) =>
  [`/organizations/${organizationId}/info`] as const;

export type OrganizationsInfoUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof organizationsInfoUpdate>>>;
export type OrganizationsInfoUpdateMutationError = ErrorType<unknown>;

export const useOrganizationsInfoUpdate = <TError = ErrorType<unknown>>(
  organizationId: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof organizationsInfoUpdate>>,
      TError,
      Key,
      OrganizationInfoUpdate,
      Awaited<ReturnType<typeof organizationsInfoUpdate>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getOrganizationsInfoUpdateMutationKey(organizationId);
  const swrFn = getOrganizationsInfoUpdateMutationFetcher(organizationId, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
export const organizationsSecurityUpdate = (
  organizationId: string,
  organizationSecurity: BodyType<OrganizationSecurity>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<OrganizationSecurity>(
    {
      url: `/organizations/${organizationId}/security`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: organizationSecurity,
    },
    options
  );
};

export const getOrganizationsSecurityUpdateMutationFetcher = (
  organizationId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return (_: Key, { arg }: { arg: OrganizationSecurity }): Promise<OrganizationSecurity> => {
    return organizationsSecurityUpdate(organizationId, arg, options);
  };
};
export const getOrganizationsSecurityUpdateMutationKey = (organizationId: string) =>
  [`/organizations/${organizationId}/security`] as const;

export type OrganizationsSecurityUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationsSecurityUpdate>>
>;
export type OrganizationsSecurityUpdateMutationError = ErrorType<unknown>;

export const useOrganizationsSecurityUpdate = <TError = ErrorType<unknown>>(
  organizationId: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof organizationsSecurityUpdate>>,
      TError,
      Key,
      OrganizationSecurity,
      Awaited<ReturnType<typeof organizationsSecurityUpdate>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getOrganizationsSecurityUpdateMutationKey(organizationId);
  const swrFn = getOrganizationsSecurityUpdateMutationFetcher(organizationId, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
export const organizationsSettingRead = (organizationId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<OrganizationSetting>(
    { url: `/organizations/${organizationId}/setting`, method: "GET" },
    options
  );
};

export const getOrganizationsSettingReadKey = (organizationId: string) =>
  [`/organizations/${organizationId}/setting`] as const;

export type OrganizationsSettingReadQueryResult = NonNullable<Awaited<ReturnType<typeof organizationsSettingRead>>>;
export type OrganizationsSettingReadQueryError = ErrorType<unknown>;

export const useOrganizationsSettingRead = <TError = ErrorType<unknown>>(
  organizationId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof organizationsSettingRead>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!organizationId;
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getOrganizationsSettingReadKey(organizationId) : null));
  const swrFn = () => organizationsSettingRead(organizationId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
export const organizationsSettingUpdate = (
  organizationId: string,
  organizationSettingUpdate: BodyType<OrganizationSettingUpdate>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<OrganizationSetting>(
    {
      url: `/organizations/${organizationId}/setting`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: organizationSettingUpdate,
    },
    options
  );
};

export const getOrganizationsSettingUpdateMutationFetcher = (
  organizationId: string,
  options?: SecondParameter<typeof customInstance>
) => {
  return (_: Key, { arg }: { arg: OrganizationSettingUpdate }): Promise<OrganizationSetting> => {
    return organizationsSettingUpdate(organizationId, arg, options);
  };
};
export const getOrganizationsSettingUpdateMutationKey = (organizationId: string) =>
  [`/organizations/${organizationId}/setting`] as const;

export type OrganizationsSettingUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof organizationsSettingUpdate>>
>;
export type OrganizationsSettingUpdateMutationError = ErrorType<unknown>;

export const useOrganizationsSettingUpdate = <TError = ErrorType<unknown>>(
  organizationId: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof organizationsSettingUpdate>>,
      TError,
      Key,
      OrganizationSettingUpdate,
      Awaited<ReturnType<typeof organizationsSettingUpdate>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof customInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getOrganizationsSettingUpdateMutationKey(organizationId);
  const swrFn = getOrganizationsSettingUpdateMutationFetcher(organizationId, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
