import styled from "styled-components";

import type { PaddingProperties } from "./system";
import { padding } from "./system";

export const Header1 = styled.h1<{ $color?: "inherit" | "text-secondary" | "text-primary" | undefined }>`
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  ${(p) => {
    switch (p.$color) {
      case "inherit":
        return {
          color: "inherit",
        };
      case "text-secondary":
        return {
          color: p.theme.vars.palette.text.secondary,
        };
      case "text-primary":
      default:
        return {
          color: p.theme.vars.palette.text.primary,
        };
    }
  }}
`;

export const Header2 = styled.h1<
  {
    color?: "primary" | "secondary" | "error" | "primary-main" | "warn-main" | undefined;
  } & Partial<PaddingProperties>
>`
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: ${(p) =>
    p.color === "primary-main"
      ? p.theme.vars.palette.primary.main
      : p.color === "secondary"
        ? p.theme.vars.palette.text.secondary
        : p.color === "error"
          ? p.theme.vars.palette.error.main
          : p.color === "warn-main"
            ? "rgba(242, 153, 74, 1)"
            : p.theme.vars.palette.text.primary};
  ${padding}
`;
export const Header3 = styled.h2`
  font-size: 16px;
  font-weight: 700;
`;

export const HeaderSmall = styled.h3<{
  color?: "primary" | "secondary" | "error" | "primary-main" | "warn-main" | "inherit" | undefined;
}>`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) =>
    p.color === "primary-main"
      ? p.theme.vars.palette.primary.main
      : p.color === "secondary"
        ? p.theme.vars.palette.text.secondary
        : p.color === "error"
          ? p.theme.vars.palette.error.main
          : p.color === "warn-main"
            ? "rgba(242, 153, 74, 1)"
            : p.color === "inherit"
              ? "inherit"
              : p.theme.vars.palette.text.primary};
`;

export const SectionHeader = styled.h4`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: ${(p) => p.theme.vars.palette.text.primary};
`;

export const Body = styled.p<{
  color?: "primary" | "secondary" | "error" | "primary-main" | "inherit" | undefined;
  weight?: "bold" | "light" | undefined;
}>`
  font-weight: ${(p) => (p.weight === "bold" ? 700 : 400)};
  font-size: 15px;
  line-height: 22px;
  color: ${(p) =>
    p.color === "primary-main"
      ? p.theme.vars.palette.primary.main
      : p.color === "secondary"
        ? p.theme.vars.palette.text.secondary
        : p.color === "error"
          ? p.theme.vars.palette.error.main
          : p.color === "inherit"
            ? "inherit"
            : p.theme.vars.palette.text.primary};
`;

export const BodySmall = styled.p<{
  color?: "primary" | "secondary" | "error" | undefined;
  weight?: "bold" | "light" | undefined;
}>`
  font-weight: ${(p) => (p.weight === "bold" ? 700 : 400)};
  font-size: 13px;
  line-height: 16px;
  color: ${(p) =>
    p.color === "secondary"
      ? p.theme.vars.palette.text.secondary
      : p.color === "error"
        ? p.theme.vars.palette.error.main
        : p.theme.vars.palette.text.primary};
`;

export const BodyXSmall = styled.p<{
  color?: "primary" | "secondary" | "error" | "light" | "inherit" | undefined;
  weight?: "bold" | "light" | undefined;
}>`
  font-weight: ${(p) => (p.weight === "bold" ? 700 : 400)};
  font-size: 12px;
  line-height: 14px;
  color: ${(p) =>
    p.color === "secondary"
      ? p.theme.vars.palette.text.secondary
      : p.color === "error"
        ? p.theme.vars.palette.error.main
        : p.color === "light"
          ? p.theme.vars.palette.text.light
          : p.color === "inherit"
            ? "inherit"
            : p.theme.vars.palette.text.primary};
`;

export const BodyXXSmall = styled.p<{
  color?: "primary" | "secondary" | "error" | "primary-main" | undefined;
  weight?: "bold" | "light" | undefined;
}>`
  font-weight: ${(p) => (p.weight === "bold" ? 700 : 400)};
  font-size: 11px;
  line-height: 13px;
  color: ${(p) =>
    p.color === "primary-main"
      ? p.theme.vars.palette.primary.main
      : p.color === "secondary"
        ? p.theme.vars.palette.text.secondary
        : p.color === "error"
          ? p.theme.vars.palette.error.main
          : p.theme.vars.palette.text.primary};
`;
export const ButtonTextLarge = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => p.theme.vars.palette.text.primary};
`;
export const ButtonTextSmall = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: ${(p) => p.theme.vars.palette.text.primary};
`;
