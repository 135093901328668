import type { ColorSystemOptions } from "@mui/material";
import { experimental_extendTheme as extendTheme } from "@mui/material/styles";
import { MOBILE_BREAKPOINT } from "../lib/breakpoints";

const lightPalette: ColorSystemOptions["palette"] = {
  primary: {
    main: "#f14c00",
    light: "#fd9f00",
    highlight: "#feeae0",
    dark: "#e91800",
    surface: "rgba(241, 76, 0, 0.3)",
    lightSurface: "rgba(241, 76, 0, 0.2)",
  },
  background: {
    default: "#f9f9f9",
    paper: "#ffffff",
    light: "#eeeeee",
    surface: "rgba(0, 0, 0, 0.03)",
    error: "rgba(255, 0, 0, 0.1)",
    experimental: {
      layeredDefault: "#ffffff",
      layeredLight: "#ffffff",
    },
    gradient: {
      main: "linear-gradient(192.09deg, #E50000 -27.68%, #FFAF00 83.93%)",
    },
  },
  text: {
    primary: "rgba(0,0,0,0.84)",
    secondary: "rgba(0,0,0,0.56)",
    light: "rgba(0,0,0,0.24)",
    disabled: "rgba(0,0,0,0.38)",
    experimental: {
      onPrimary: "#fff",
    },
  },
  error: {
    main: "#F77B74",
    strong: "#FF0000",
  },
  divider: "rgba(0,0,0,0.13)",
  border: "#dddddd",
};

const darkPalette: ColorSystemOptions["palette"] = {
  primary: {
    main: "#f14c00",
    highlight: "#feeae0", // FIXME: dark mode 用の値を決める
    light: "#fd9f00",
    dark: "#e91800",
    surface: "rgba(241, 76, 0, 0.3)",
    lightSurface: "rgba(241, 76, 0, 0.2)",
  },
  background: {
    default: "#252525",
    paper: "#111111",
    light: "#444444",
    surface: "rgba(255, 255, 255, 0.03)",
    error: "rgba(255, 0, 0, 0.1)",
    experimental: {
      layeredDefault: "#252525",
      layeredLight: "#444444",
    },
    gradient: {
      main: "linear-gradient(192.09deg, #E50000 -27.68%, #FFAF00 83.93%)",
    },
  },
  text: {
    primary: "rgba(255,255,255,0.8)",
    secondary: "rgba(255,255,255,0.56)",
    light: "rgba(255,255,255,0.24)",
    disabled: "rgba(255,255,255,0.38)",
    experimental: {
      onPrimary: "#fff",
    },
  },
  error: {
    main: "#F77B74",
    strong: "#FF0000",
  },
  divider: "rgba(255,255,255,0.13)",
  border: "#666666",
};

export const theme = extendTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.56)",
          },
          // @media (hover: none)
          // .MuiIconButton-root:hover
          // background-color: transparent;のスタイルを打ち消す為にオブジェクトのみ定義
          "&:hover": {},
        },
      },
    },
  },
  typography: {
    fontFamily: `ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,
    button: {
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      xs: 0, // default
      sm: MOBILE_BREAKPOINT, // default 600
      md: 900, // default
      lg: 1200, // default
      xl: 1536, // default
    },
  },
  colorSchemes: {
    light: {
      palette: lightPalette,
    },
    dark: {
      palette: darkPalette,
    },
  },
  cssVarPrefix: "rimo",
});

export type ThemeType = typeof theme;

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    border: string;
  }

  interface PaletteOptions {
    border: string;
  }

  interface SimplePaletteColorOptions {
    highlight?: string;
    surface?: string;
    lightSurface?: string;
    strong?: string;
  }

  interface PaletteColor {
    highlight?: string;
    surface?: string;
    lightSurface?: string;
    strong?: string;
  }

  interface TypeText {
    light: string;
    experimental: {
      onPrimary: string;
    };
  }

  interface TypeBackground {
    light: string;
    surface: string;
    error: string;
    experimental: {
      layeredDefault: string;
      layeredLight: string;
    };
    gradient: {
      main: string;
    };
  }
}
