import type { PropsWithChildren } from "react";
import React from "react";
import type { DefaultTheme} from "styled-components";
import styled, { css } from "styled-components";

const px2rem = (px: number) => px / 16;

type ButtonSize = "normal" | "middle" | "large";
type ButtonVariant = "normal" | "primary" | "text" | "outline";
type ButtonR = 6;

export interface ButtonProps {
  size?: ButtonSize;
  variant?: ButtonVariant;
  r?: ButtonR;
  fullWidth?: boolean | undefined;
}

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps;

const ButtonRFC = React.forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  ({ children, size = "normal", variant = "normal", r = 6, fullWidth = false, ...rest }, ref) => {
    return (
      <ButtonInternal {...rest} ref={ref} $size={size} $variant={variant} $r={r} $fullWidth={fullWidth}>
        {children}
      </ButtonInternal>
    );
  }
);

export const Button = React.memo(ButtonRFC);

const IconButtonRFC = React.forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  ({ children, size = "normal", variant = "normal", r = 6, fullWidth = false, ...rest }, ref) => {
    return (
      <IconButtonInternal {...rest} ref={ref} $size={size} $variant={variant} $r={r} $fullWidth={fullWidth}>
        {children}
      </IconButtonInternal>
    );
  }
);

export const IconButton = React.memo(IconButtonRFC);

export type CssButtonProps = { $size: ButtonSize; $variant: ButtonVariant; $r: ButtonR; $fullWidth: boolean };
export const cssButton = css<CssButtonProps>`
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  margin: 4px 6px;
  min-width: ${px2rem(88)}rem;
  padding: ${({ $size }) => `${getPaddingVertical($size)} ${getPaddingHorizontal($size)}`};
  background-blend-mode: overlay;
  background-color: transparent;
  background-image: ${({ theme, $variant }) => getBackgroundColor($variant, theme)};
  color: ${(p) => getTextColor(p.$variant, p.theme)};
  font-weight: ${({ $variant }) => ($variant === "text" ? 400 : 700)};
  font-size: ${px2rem(12)}rem;
  line-height: 1.17;
  border-color: ${({ theme, $variant }) => getBorderColor($variant, theme)};
  border-style: solid;
  border-width: 1px;
  border-radius: ${(p) => px2rem(p.$r)}rem;
  outline: none;
  cursor: pointer;
  transition: all 200ms;
  &:hover,
  &.__hover {
    background-color: ${({ theme, $variant }) => getHoveredBackgroundColor($variant, theme)};
    color: ${({ theme, $variant }) => getHoveredTextColor($variant, theme)};
  }
  &:active,
  &.__active {
    box-shadow: ${({ theme }) =>
      theme.mode === "dark" ? "0px 0px 8px 0px rgba(241, 76, 0, 0.8)" : "0px 0px 8px 0px rgba(241, 76, 0, 0.6)"};
  }
  &:disabled,
  &.__disabled {
    background-image: none;
    background-color: ${({ theme, $variant }) => getDisabledBackgroundColor($variant, theme)};
    color: ${({ theme, $variant }) => getDisabledTextColor($variant, theme)};
    box-shadow: none;
    cursor: default;
    border-color: transparent;
  }
  &:focus-visible {
    border-color: ${({ theme }) => theme.vars.palette.primary.main};
  }
`;

const ButtonInternal = styled.button<CssButtonProps>`
  ${cssButton}
  min-width: ${px2rem(88)}rem;
  padding: ${({ $size }) => `${getPaddingVertical($size)} ${getPaddingHorizontal($size)}`};
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
`;

const IconButtonInternal = styled.button<CssButtonProps>`
  ${cssButton}
  width: ${({ $size }) => getIconButtonSize($size)};
  border-radius: 100px;
  svg {
    width: 20px;
    height: 20px;
  }
`;
const getIconButtonSize = (size: ButtonSize) => {
  switch (size) {
    case "normal": {
      return `32px`;
    }
    case "middle": {
      return `40px`;
    }
    case "large": {
      return `48px`;
    }
    default:
      break;
  }
};
const getPaddingVertical = (size: ButtonSize) => {
  switch (size) {
    case "normal": {
      return `${px2rem(8)}rem`;
    }
    case "middle": {
      return `${px2rem(12)}rem`;
    }
    case "large": {
      return `${px2rem(16)}rem`;
    }
    default:
      break;
  }
};

const getPaddingHorizontal = (size: ButtonSize): string => {
  switch (size) {
    case "middle":
      return `${px2rem(16)}rem`;
    case "normal":
    case "large":
    default:
      return `${px2rem(14)}rem`;
  }
};

const getTextColor = (variant: ButtonVariant, theme: DefaultTheme) => {
  switch (variant) {
    case "normal": {
      return theme.vars.palette.text.primary;
    }
    case "primary": {
      return "#fff";
    }
    case "text":
    case "outline": {
      return theme.vars.palette.primary.main;
    }
    default:
      break;
  }
};

const getBackgroundColor = (variant: ButtonVariant, theme: DefaultTheme) => {
  switch (variant) {
    case "normal": {
      return `linear-gradient(${theme.vars.palette.background.light}, ${theme.vars.palette.background.light})`;
    }
    case "primary": {
      return `linear-gradient(${theme.vars.palette.primary.main}, ${theme.vars.palette.primary.main})`;
    }
    case "text":
    case "outline": {
      return "none";
    }
    default:
      break;
  }
};

const getHoveredBackgroundColor = (variant: ButtonVariant, theme: DefaultTheme) => {
  switch (variant) {
    case "normal": {
      return `rgba(0, 0, 0, 0.1)`;
    }
    case "primary": {
      return `rgba(0, 0, 0, 0.1)`;
    }
    case "text": {
      return theme.vars.palette.background.surface;
    }
    case "outline": {
      return theme.vars.palette.primary.main;
    }
    default:
      break;
  }
};

const getDisabledBackgroundColor = (variant: ButtonVariant, theme: DefaultTheme) => {
  switch (variant) {
    case "normal":
    case "outline":
    case "text":
    case "primary": {
      return theme.vars.palette.background.light;
    }
    default:
      break;
  }
};

const getDisabledTextColor = (variant: ButtonVariant, theme: DefaultTheme) => {
  switch (variant) {
    case "normal":
    case "primary":
    case "outline":
    case "text": {
      return theme.vars.palette.text.disabled;
    }
    default:
      break;
  }
};

const getHoveredTextColor = (variant: ButtonVariant, theme: DefaultTheme) => {
  switch (variant) {
    case "normal": {
      return theme.vars.palette.text.primary;
    }
    case "primary":
    case "outline": {
      return "#fff";
    }
    case "text": {
      return theme.vars.palette.primary.main;
    }
    default:
      break;
  }
};

const getBorderColor = (variant: ButtonVariant, theme: DefaultTheme) => {
  switch (variant) {
    case "normal":
    case "primary":
    case "text": {
      return "transparent";
    }
    case "outline": {
      return theme.vars.palette.primary.main;
    }
    default:
      break;
  }
};
