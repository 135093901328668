import { RimoBackendUrl } from "@rimo/frontend/constants/services";
import decamelizeKeys from "decamelize-keys";
import camelcaseKeys from "camelcase-keys";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig } from "axios";

export const isStorybook = process.env.STORYBOOK === "true";

export const AXIOS_INSTANCE = axios.create({
  baseURL: !isStorybook ? RimoBackendUrl : "",
  timeout: 120_000,
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "json",
});

/** @public */
export const customInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const req: AxiosRequestConfig = { ...config, params: { ...config.params }, ...options };
  if (req.method === "GET" && req.params) req.params = decamelizeKeys(req.params);
  if (["POST", "PUT", "PATCH"].includes(req.method ?? "GET") && req.data) {
    req.data = decamelizeKeys(req.data, { deep: true });
  }
  return AXIOS_INSTANCE(req).then(({ data }) => camelcaseKeys(data, { deep: true }));
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
/** @public */
export type ErrorType<Error> = AxiosError<Error>;

/** @public */
export type BodyType<BodyData> = BodyData;
