"use client";
import React, { useContext } from "react";
import type { KeyedMutator } from "swr";
import { AlertNetwork } from "../components/AlertNetwork";
import { UserContext } from "./UserContext";
import { useOrganizationsList, useOrganizationsRead } from "../api-client/organization/organization";
import type { Organization } from "../api-client/models";

export type OrganizationState = {
  data?: Organization[];
};

type Dispatch = null | {
  mutate: KeyedMutator<Organization[]> | KeyedMutator<Organization>;
};

const initialStateFactory = (initialState?: Partial<OrganizationState>): OrganizationState => ({
  data: undefined,
  ...initialState,
});

const OrganizationStateContext = React.createContext<OrganizationState>(initialStateFactory());
const OrganizationsDispatchContext = React.createContext<Dispatch>(null);

export const OrganizationProvider: React.FC<
  React.PropsWithChildren<{ initialState?: Partial<OrganizationState>; id?: string | null }>
> = (props) => {
  const { data, error, mutate } = useOrganizationData(props.id);
  return (
    <OrganizationStateContext.Provider value={{ data }}>
      <OrganizationsDispatchContext.Provider value={{ mutate }}>{props.children}</OrganizationsDispatchContext.Provider>
      <AlertNetwork error={Boolean(error)} title="企業情報" />
    </OrganizationStateContext.Provider>
  );
};

const useOrganizationData = (key: string | undefined | null) => {
  /** ログアウトすると企業情報はリセットされる => undefined */
  const { userState } = useContext(UserContext);
  const { user } = userState;

  // include_plan: https://admin.rimo.app/organizations/:teamID/payment_histories で使ってる
  const {
    data: list,
    error: listErr,
    mutate: listMutate,
  } = useOrganizationsList(
    {
      includePlan: true,
    },
    {
      swr: {
        enabled: !!user && key === undefined,
        revalidateIfStale: false,
        revalidateOnReconnect: false,
        revalidateOnFocus: false,
      },
    }
  );
  const {
    data: singleData,
    error: singleErr,
    mutate: singleMutate,
  } = useOrganizationsRead(
    key ?? "",
    {
      includePlan: true,
    },
    {
      swr: {
        enabled: !!user && !!key,
        revalidateIfStale: false,
        revalidateOnReconnect: false,
        revalidateOnFocus: false,
      },
    }
  );

  if (!key) {
    return { data: list, error: listErr, mutate: listMutate };
  }
  if (singleData) {
    return { data: [singleData], error: singleErr, mutate: singleMutate };
  }
  return { data: undefined, error: singleErr, mutate: singleMutate };
};

function useOrganizationState() {
  return useContext(OrganizationStateContext);
}

export function useOrganizationDispatch() {
  const context = useContext(OrganizationsDispatchContext);
  if (!context) throw new Error("should load dispatch context");
  return context;
}

export function useOrganizations() {
  const state = useOrganizationState();
  return state.data;
}
