"use client";
import React, { useContext, useEffect, useMemo, useState } from "react";
import type { User } from "firebase/auth";
import { getIdToken } from "firebase/auth";
import { setGoogleAnalyticsUser } from "../services/conversion/Google";
import { setupAxiosRequestInterceptor, setupAxiosResponseInterceptor, clearAllAxiosInterceptors } from "../api";
import { isDev } from "../utils/system";
import Sentry from "../utils/sentry";
import { identifyLDUser } from "../utils/ld";
import type { UserExtraInfo } from "../services/firebase/auth/user";
import { AXIOS_INSTANCE } from "@rimo/frontend/api/customInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CustomClaims = { [x: string]: any };

export type UserStateProps = {
  isLoaded: boolean;
  user: User | null;
  claims: CustomClaims | null;
};

type ActionProps =
  | {
      type: "INIT_AS_UNKNOWN" | "LOGOUT";
    }
  | {
      type: "LOGIN";
      payload: UserExtraInfo;
    }
  | {
      type: "UPDATE";
      payload: {
        user: User;
        claims?: CustomClaims;
      };
    };

const reducer: React.Reducer<UserStateProps, ActionProps> = (state, action): UserStateProps => {
  if (isDev) {
    console.group("State(User)");
    console.log("type: ", action.type);
    console.log("current: ", state);
    if ("payload" in action) console.log("payload: ", action.payload);
    console.groupEnd();
  }

  switch (action.type) {
    case "INIT_AS_UNKNOWN": {
      setupAxiosResponseInterceptor();
      setupAxiosResponseInterceptor(AXIOS_INSTANCE);
      return {
        isLoaded: true,
        user: null,
        claims: null,
      };
    }
    case "LOGIN": {
      const { user, idToken } = action.payload;
      const uid = user.uid;

      // Sentry(バグリポート)
      Sentry.withScope((scope) => {
        scope.setUser({
          id: uid,
          email: user.email ?? undefined,
          username: user.displayName ?? undefined,
          authTime: idToken.authTime,
          signInProvider: idToken.signInProvider,
        });
      });

      const orgId = idToken.claims.organization_id?.toString();
      const custom = orgId ? { organization: orgId } : undefined;

      identifyLDUser({ key: uid, custom }).catch((e) => e);

      // アナリティクス
      setGoogleAnalyticsUser(user);

      // todo idToken.tokenがJWT(Authorizationヘッダ)に利用出来る
      // APIリクエストのインターセプターを定義
      setupAxiosRequestInterceptor(user);
      setupAxiosResponseInterceptor();

      setupAxiosRequestInterceptor(user, AXIOS_INSTANCE);
      setupAxiosResponseInterceptor(AXIOS_INSTANCE);

      return {
        isLoaded: true,
        user,
        claims: idToken.claims,
      };
    }
    case "UPDATE": {
      return {
        ...state,
        user: action.payload.user,
        claims: action.payload.claims ?? state.claims,
      };
    }
    case "LOGOUT": {
      clearAllAxiosInterceptors();
      return {
        isLoaded: true,
        user: null,
        claims: null,
      };
    }
    default: {
      return state;
    }
  }
};

const initialState: UserStateProps = {
  isLoaded: false,
  user: null,
  claims: null,
};

export const UserContext = React.createContext<{
  userState: UserStateProps;
  userDispatch: React.Dispatch<ActionProps>;
}>({
  userState: initialState,
  /* eslint-disable-next-line @typescript-eslint/no-empty-function */
  userDispatch: (() => {}) as React.Dispatch<ActionProps>,
});

export const UserProvider: React.FC<React.PropsWithChildren<{ initialState?: UserStateProps }>> = ({
  children,
  initialState: overrideInitialState,
}) => {
  const [userState, userDispatch] = React.useReducer(reducer, overrideInitialState ?? initialState);
  const value = useMemo(() => ({ userState, userDispatch }), [userState]);
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

/** @deprecated */
export function useIdToken(forceRefresh?: boolean) {
  const [token, setToken] = useState<string>();
  const { userState } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      if (!userState.user) return;
      const result = await getIdToken(userState.user, forceRefresh);
      setToken(result);
    })();
  }, [userState, forceRefresh]);

  return token;
}

export const useLoginUser = () => {
  const { userState } = useContext(UserContext);
  return userState.user;
};

export function useUserId(): string {
  const { userState } = useContext(UserContext);
  return userState.user?.uid ?? "";
}

export function useUserPhotoUrl(): string | undefined {
  const { userState } = useContext(UserContext);
  const data = userState.user?.providerData;
  return !data ? undefined : data.reduce<string | undefined>((prev, cur) => cur?.photoURL ?? prev, undefined);
}

export function useClaims(): { organizationId?: string | null } {
  const {
    userState: { claims },
  } = useContext(UserContext);
  return claims === undefined
    ? {
        organizationId: undefined,
      }
    : claims === null
      ? {
          organizationId: null,
        }
      : {
          organizationId: claims.organization_id,
        };
}
