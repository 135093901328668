import type { PropsWithChildren, FC } from "react";
import styled, { type WebTarget, type DefaultTheme, type CSSProperties, css } from "styled-components";

import type { WidthProperty } from "./system";
import { width } from "./system";

import type { TransientProps } from "./transientProps";

type FontSize = number;
type FontWeight = "light" | "bold" | "inherit" | number;
export type TypographyColor =
  | "text-primary"
  | "text-secondary"
  | "text-light"
  | "primary"
  | "secondary"
  | "error"
  | "warn"
  | "inherit"
  | string;
type LineHeight = string | number;

type TextAlign = Required<CSSProperties["textAlign"]>;
type WordBreak = Required<CSSProperties["wordBreak"]>;
type FontSizeUnit = "px" | "rem";

const defaultValues = {
  color: "text-primary",
  fontSize: 14,
  fontWeight: "light",
  lineHeightRatio: 1.19,
  textAlign: "start",
  sizeUnit: "px",
  wordBreak: "normal",
} as const;

interface BaseTypographyProps {
  as: WebTarget;
  color: TypographyColor;
  /**
   * This value will be ignored if you use sizeUnit("rem") and lineHeight("px")
   */
  lineHeight: LineHeight;
  size: FontSize;
  weight: FontWeight;
  textAlign: TextAlign;
  sizeUnit: FontSizeUnit; // どちらかに今後統一したい
  wordBreak: WordBreak;
  overflow: string;
  textOverflow: string;
  whiteSpace: string;
  width: Required<CSSProperties["width"]>;
}

export type TypographyProps = PropsWithChildren<Partial<BaseTypographyProps & WidthProperty>>;
// export const Typography: FC<TypographyProps> = (props) => <StyledTypography {...props} />;
export const Typography: FC<TypographyProps> = (props) => {
  const {
    color,
    lineHeight,
    size,
    weight,
    textAlign,
    sizeUnit,
    wordBreak,
    overflow,
    textOverflow,
    whiteSpace,
    ...restProps
  } = props;
  return (
    <StyledTypography
      $color={color}
      $lineHeight={lineHeight}
      $size={size}
      $weight={weight}
      $textAlign={textAlign}
      $sizeUnit={sizeUnit}
      $wordBreak={wordBreak}
      $overflow={overflow}
      $textOverflow={textOverflow}
      $whiteSpace={whiteSpace}
      {...restProps}
    />
  );
};

type TypographyTransientProps = TransientProps<BaseTypographyProps, "as">;

export const typographyStyles = css<Partial<TypographyTransientProps>>`
  color: ${({ theme, $color }) => getColor(theme, $color)};
  font-size: ${({ $size, $sizeUnit }) => getFontSize($size, $sizeUnit)};
  font-weight: ${({ $weight }) => getFontWeight($weight)};
  line-height: ${({ $lineHeight, $sizeUnit }) => getLineHeight($lineHeight, $sizeUnit)};
  text-align: ${({ $textAlign }) => $textAlign || defaultValues.textAlign};
  word-break: ${({ $wordBreak }) => $wordBreak || defaultValues.wordBreak};
  overflow: ${({ $overflow }) => $overflow || "visible"};
  text-overflow: ${({ $textOverflow }) => $textOverflow || "clip"};
  white-space: ${({ $whiteSpace }) => $whiteSpace || "normal"};
`;

export type CssColorProps = { $color: TypographyColor };
export const cssColor = css<Partial<CssColorProps>>`
  color: ${({ theme, $color }) => getColor(theme, $color)};
`;

const StyledTypography = styled.p<TypographyTransientProps & WidthProperty>`
  ${typographyStyles}
  ${width}
`;

const getFontSize = (size: FontSize | undefined, sizeUnit: FontSizeUnit | undefined): string => {
  size ??= defaultValues.fontSize;
  sizeUnit ??= defaultValues.sizeUnit;
  return size + sizeUnit;
};

const getColor = (theme: DefaultTheme, color: TypographyColor | undefined): string => {
  color ??= defaultValues.color;
  switch (color) {
    case "text-primary":
      return theme.vars.palette.text.primary;
    case "text-secondary":
      return theme.vars.palette.text.secondary;
    case "text-light":
      return theme.vars.palette.text.light;
    case "primary":
      return theme.vars.palette.primary.main;
    case "secondary":
      return theme.vars.palette.secondary.main;
    case "error":
      return theme.vars.palette.error.main;
    case "warn":
      return "rgba(242, 153, 74, 1)";
    case "inherit":
      return "inherit";
    default:
      return color;
  }
};

const getLineHeight = (lineHeight: LineHeight | undefined, sizeUnit: FontSizeUnit | undefined): string | number => {
  if (sizeUnit === "rem") {
    if (typeof lineHeight === "number") {
      return lineHeight; // ratio
    }
    if (typeof lineHeight === "string" && lineHeight.endsWith("rem")) {
      return lineHeight; // rem
    }
    return defaultValues.lineHeightRatio; // "px" will be ignored
  } else {
    if (typeof lineHeight === "number") {
      return lineHeight + "px";
    }
    if (typeof lineHeight === "string") {
      return lineHeight;
    }
    // todo
    // 今後はpxの場合は、すべてline-heightをpxにて固定させ、remの場合はline-heightをratioで固定するようにしたい
    return defaultValues.lineHeightRatio;
  }
};

const getFontWeight = (value: FontWeight | undefined): number | string => {
  value ??= defaultValues.fontWeight;
  if (typeof value === "number") {
    return value;
  }
  if (value === "bold") {
    return 700;
  }
  if (value === "light") {
    return 400;
  }
  return "inherit";
};
