import {
  GoogleAnalyticsId,
  GoogleAwConversionId,
  GoogleFormConversionSendTo,
  KimereruConversionSendTo,
} from "../../constants/services";
import type { User } from "firebase/auth";
import { generateRandomKey } from "../../utils/generateRandomKey";

function install(scriptId: string, trackingId: string) {
  if (document.getElementById(scriptId)) return;

  const { head } = document;
  const script = document.createElement("script");
  script.id = scriptId;
  script.type = "text/javascript";
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  head.insertBefore(script, head.firstChild);

  sendGoogleConversion("js", new Date());
}

export function sendGoogleConversion(..._: (object | string | Date)[]) {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push(arguments); // eslint-disable-line prefer-rest-params
}

export function sendGoogleConversionPlain(data: object) {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push(data); // eslint-disable-line prefer-rest-params
}

export function installGtag() {
  GoogleAnalyticsId && install("ga-gtag", GoogleAnalyticsId);
}

// https://developers.google.com/gtagjs/reference/ga4-events#purchase

export function googleUploadConversion(mediaType: string, amounts: number) {
  const currency = "JPY";
  const affiliation = "Rimo Voice(web)";
  const tax = 0; // NOTE: Should I think about it properly?
  const revenue = amounts; // NOTE: Should I think about it properly?
  const item = {
    item_id: `${mediaType}_${amounts}`,
    item_name: mediaType,
    quantity: 1,
    affiliation: "Rimo Voice(web)",
    price: amounts,
    tax,
    currency,
  };
  const purchaseEvent = {
    transaction_id: generateRandomKey(),
    affiliation,
    items: [item],
    currency,
    tax,
    value: revenue,
  };
  sendGoogleConversion("event", "upload", item);

  sendGoogleConversion("event", "purchase", purchaseEvent);
}

export function onRegisterCard() {
  sendGoogleConversion("event", "add_payment_info", {});
}

export function onSinedInButtonClick(loginMethod: string) {
  sendGoogleConversion("event", "login", { method: loginMethod, user_id: null, uid: null });
}

export function setGoogleAnalyticsUser(user?: User) {
  if (!user) return;
  sendGoogleConversionPlain({ user_id: user.uid, uid: user.uid });
  sendGoogleConversion("event", "login", { method: null, user_id: user.uid, uid: user.uid });

  if (!GoogleAnalyticsId || !user) return;
  sendGoogleConversion("config", GoogleAnalyticsId, {
    user_id: user.uid,
  });
}

export function sendSignUpEvent(providerId: string) {
  sendGoogleConversion("event", "sign_up", { method: providerId });
}

export function sendGoogleAwConversion() {
  if (GoogleAwConversionId) {
    sendGoogleConversion("config", GoogleAwConversionId);
  }
}

// ref: https://support.google.com/google-ads/answer/6331304?hl=ja#zippy=%2Cgoogle-%E3%82%BF%E3%82%B0%E3%82%92%E4%BD%BF%E7%94%A8%E3%81%97%E3%81%A6%E3%82%B3%E3%83%B3%E3%83%90%E3%83%BC%E3%82%B8%E3%83%A7%E3%83%B3-%E3%83%88%E3%83%A9%E3%83%83%E3%82%AD%E3%83%B3%E3%82%B0%E3%82%92%E8%A8%AD%E5%AE%9A%E3%81%99%E3%82%8B
export function sendGoogleFormConversion() {
  if (GoogleFormConversionSendTo) {
    sendGoogleConversion("event", "conversion", {
      send_to: GoogleFormConversionSendTo,
    });
  }
}
export const sendGoogleFormConversionAsKimereru = () => {
  if (KimereruConversionSendTo) {
    sendGoogleConversion("event", "conversion", {
      send_to: KimereruConversionSendTo,
    });
  }
};
