import type { AxiosError } from "axios";
import type { Arguments, Key, SWRConfiguration, SWRResponse, BareFetcher } from "swr";
import useSWR from "swr";

import { client as axiosClient } from "../api";
import { useUserId } from "../contexts/UserContext";

/**
 * 通常時利用するHttpクライアントの呼び出し処理
 */
function backendFetcher<T>(key: Key): Promise<T> {
  if (typeof key === "string") {
    return axiosClient.get<T>(key).then((r) => r.data);
  } else if (Array.isArray(key)) {
    return axiosClient.get<T>(key[0]).then((r) => r.data);
  } else if (key && typeof key === "object" && "url" in key) {
    return axiosClient(key).then((r) => r.data);
  } else {
    throw new Error("unreachable");
  }
}

/**
 * ArgumentsがHttp通信してよいか判定する
 * (文字列) 有効な文字列である(=空文字列でない)
 * (配列) 先頭要素が有効な文字列である
 * (連想配列) urlキーに対応する値が有効な文字列である
 */
function validate(args: Arguments): boolean {
  // null, undefined, false値は後の判断をしやすくするため、先に処理してしまう
  if (args === null || args === undefined || args === false) {
    return false;
  }
  if (typeof args === "string") {
    return Boolean(args);
  } else if (Array.isArray(args)) {
    return args.length > 0 && typeof args[0] === "string" && Boolean(args[0]);
  } else if (typeof args === "object") {
    return "url" in args && Boolean(args["url"]);
  } else {
    return false;
  }
}

type BackendSWRConfiguration<Data = unknown> = {
  /** 未ログイン状態のユーザーからのリクエストを許可する (default: false) */
  allowAnonymous?: boolean | undefined;
} & SWRConfiguration<Data>;

/**
 * rimo-backendからデータフェッチする useSWR をラップしたカスタムHooks
 *
 * ログイン済みユーザーに対して利用するを基本とするが、条件付きで未ログインユーザーへの利用も許可する(通常利用時、未ログインユーザーからのリクエストはキャンセルされる)
 *
 * @example
 * useBackend("/voice/notes");
 * useBackend(`/public/notes/${id}`);
 * useBackend(["/public/notes/", owner], (path, owner, token) => rimoBackendFetcher(path, { params: { owner }, token }));
 * useBackend("/public/notes/", { allowAnonymous: true });
 * useBackend(uid !== "" && `/voice/zoom/users?uid=${uid}`, (key, uid) => axiosClient.get(key));
 * useBackend(org && `/teams/${org.id}/plans`)
 * useBackend({ url: "/voice/notes" })
 * useBackend({ url: "/voice/google/authorize", method: "post", headers: { Authorization: `Bearer ${token}` }, data: { code, rimoForceRefreshToken: true }})
 * */
export function useBackend<T = unknown>(key: Key, config?: BackendSWRConfiguration<T>): SWRResponse<T, AxiosError>;
export function useBackend<T = unknown>(
  key: Key,
  fetcher?: BareFetcher<T>,
  config?: BackendSWRConfiguration<T>
): SWRResponse<T, AxiosError>;
export function useBackend<T = unknown>(
  key: Key,
  fetcherOrConfig?: BareFetcher<T> | BackendSWRConfiguration<T>,
  config?: BackendSWRConfiguration<T>
): SWRResponse<T, AxiosError> {
  if (!config && typeof fetcherOrConfig !== "function") {
    config = fetcherOrConfig;
  }
  const fetcher = typeof fetcherOrConfig === "function" ? fetcherOrConfig : backendFetcher;
  const { allowAnonymous = false, ...configData } = config ?? {};
  const uid = useUserId();
  const swrKey = buildBackendSWRKey(key, uid, allowAnonymous);

  return useSWR<T, AxiosError>(swrKey, fetcher, {
    revalidateOnFocus: false,
    ...configData,
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function buildBackendSWRKey(key: Key, uid: string, allowAnonymous: boolean): Key {
  const hasUserId = Boolean(uid);

  // 未ログイン時(!hasUserId)に許可されないAPIリクエスト(allowAnonymous=false)はキャンセルされる
  const disabledHttpRequest = allowAnonymous === false && !hasUserId;

  const args = typeof key === "function" ? (key as () => Arguments)() : key;

  return disabledHttpRequest || !validate(args)
    ? null
    : typeof args === "string"
      ? [args, uid]
      : Array.isArray(args)
        ? [...args, uid]
        : args && typeof args === "object"
          ? { ...args, _uid: uid } // _uidはバッティングを回避させる目的
          : null;
}
